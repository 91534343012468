@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        background: url("../public/assets/background.png") repeat;
        width: 100%;
        zoom: 100%;
    }

    @media only screen and (max-width: 1200px) {
        body {
            zoom: 90%;
        }
    }

    @media only screen and (max-width: 1000px) {
        body {
            zoom: 100%;
        }
    }

    @media only screen and (max-width: 250px) {
        body {
            zoom: 30%;
        }
    }

    .navigation {
        background: url("../public/assets/navigation.png") repeat;
    }

    /* footer {
        background: url("../public/assets/footer.jpg") repeat;
    } */
}
